/* eslint-disable sonarjs/no-duplicate-string */
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

const ENV = process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod';

// REDIRECT TO ANGULAR URLS false : true
const USE_ANGULAR_URL_BUY_BUTTON = false; // Redirect to Angular Buy/Gift Buttons
// const isKlarnaEnabled = JSON.parse(
//   process.env.NEXT_PUBLIC_KLARNA_ENABLED === 'true',
// );
const isKlarnaEnabled = false;
export const DEFAULT_PUBLIC_API = 'https://public-api.5pm.co.uk';
export const DEFAULT_PUBLIC_API_TEST = 'https://public-api05.dev5pm.co.uk';

export const LOGIN_URL = 'login';
export const REGISTER_URL = 'register';
export const WISHLIST_URL = 'wishlist';

export const MAP_VIEW_URL = '/map-view';

export const DEFAULT_SUGGGEST_API = {
  dev: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestwow`,
  nxt: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestwow`,
  prod: `${process.env.NEXT_PUBLIC_SUGGESTER_API}/suggestwow`,
};
export const PREFERENCE_CENTRE = `${process.env.NEXT_PUBLIC_PREFERENCE_CENTRE_API}/email/preferences`;

export const GIFTFINDER_CONFIG = {
  dev:
    'https://s3.eu-west-1.amazonaws.com/static10.devwowcher.co.uk/binaries/gift-finder-wow_v1.json',
  nxt:
    'https://s3.eu-west-1.amazonaws.com/static01.nxtwowcher.co.uk/binaries/gift-finder-wow_v1.json',
  prod:
    'https://s3.eu-west-1.amazonaws.com/static.wowcher.co.uk/binaries/gift-finder-wow_v1.json',
};
export const LINKS_GLOBAL_5PM = {
  dev: process.env.NEXT_PUBLIC_BASE_ROOT_URL || 'https://www09.dev5pm.co.uk',
  nxt: process.env.NEXT_PUBLIC_BASE_ROOT_URL || 'https://www01.nxt5pm.co.uk',
  prod: 'https://www.5pm.co.uk',
};

// need to change this
const SPLUNK_BASE_URL_DEV =
  'https://internal-splunk-dev.wowcher.co.uk/services/collector/event';
const SPLUNK_BASE_URL_PROD =
  'https://internal-splunk.wowcher.co.uk/services/collector/raw';

export const LINKS_GLOBAL_SPLUNK = {
  dev: SPLUNK_BASE_URL_DEV,
  nxt: SPLUNK_BASE_URL_DEV,
  prod: SPLUNK_BASE_URL_PROD,
};

export const LINKS_GLOBAL_SECURE = {
  dev: 'https://secure05.dev5pm.co.uk',

  nxt: 'https://secure01.nxt5pm.co.uk',

  prod: 'https://www.5pm.co.uk',
};

export const ANGULAR_HOME_URL = `${LINKS_GLOBAL_5PM[ENV]}`;

export const LINK_HOME = `${LINKS_GLOBAL_5PM[ENV]}`;

export const LINK_FORGOT_PASSWORD = `/forgot-password`;

export const MARKETING_CLAIMS_DEF_LINK =
  'https://www.5pm.co.uk/page/marketingdefinitions.html';

export const LINK_LOGGED_USER = LINKS_GLOBAL_5PM[ENV];
export const LINK_SEARCH_PAGE = `${LINKS_GLOBAL_5PM[ENV]}/search`;
export const LINK_NOT_LOGGED_USER = `${LINKS_GLOBAL_5PM[ENV]}/${LOGIN_URL}`; // Dont need to be the same as login
export const LINK_LOGIN = `/${LOGIN_URL}`;
export const LINK_WISHLIST = `/${WISHLIST_URL}`;
export const LINK_MY_VOUCHERS = `/myaccount/vouchers`;
export const LINK_MY_VOUCHERS_DELETE = `/myaccount/delete`;
export const LINK_REGISTER = `/register`;
// absolute link to fix occasional payment bug going from checkout to second-checkout: PRD-2708
export const LINK_SECOND_CHECKOUT = `${LINKS_GLOBAL_5PM[ENV]}/order-addon`;
export const LINK_CHECKOUT = `/checkout`;
export const LINK_CHECKOUT_RELATIVE = `/checkout`;
export const LINK_MYACCOUNT_PASSWORD = `/myaccount/password`;
export const LINK_SECOND_CHECKOUT_BENEFITS = `${LINKS_GLOBAL_5PM[ENV]}/second-checkout/benefits`;
export const LINK_CHECKOUT_BUTTON = `${
  (USE_ANGULAR_URL_BUY_BUTTON && LINKS_GLOBAL_SECURE[ENV]) || ''
}/checkout`;

export const LINK_PREFERENCE_CENTRE = `${LINKS_GLOBAL_5PM[ENV]}/email/preferences`;
export const LINK_MAIN_HOME = `${LINKS_GLOBAL_5PM[ENV]}`;

export const LINK_GIFT_FINDER = (location) => `/deal/${location}/gift-finder`;

export const RESET_PASSWORD = '/request-password-reset';

// update this with correct 5pm value
// APP STORES
export const LINK_APPLE_APP_STORE =
  'https://wowcher.onelink.me/j47e/IOSwebsitelozenge';
export const LINK_GOOGLE_PLAY_STORE =
  'https://wowcher.onelink.me/j47e/ANDwebsitelozenge';

// APP BANNER
export const APP_BANNER_DEEPLINK_APPLE = 'https://wowcher.app.link/mIzxxPXGI4';

export const APP_BANNER_DEEPLINK_ANDROID =
  'https://wowcher.app.link/mIzxxPXGI4';

export const LINK_CAREERS = 'https://careers.5pm.co.uk/careers.html';

// update this with correct 5pm value
export const SOCIAL_MEDIA_SHARE_LINKS = [
  {
    ariaLabel: 'Facebook Page',
    backgroundColor: '#0052a3',
    href: 'https://www.facebook.com/koobleit',
    icon: faFacebookF,
  },
  {
    ariaLabel: 'Twitter Page',
    backgroundColor: '#4099ff',
    href: 'https://x.com/5pm',
    icon: faTwitter,
  },
  {
    ariaLabel: 'Instagram Page',
    backgroundColor: '#125688',
    href: 'https://www.instagram.com/kooble_scotland/',
    icon: faInstagram,
  },
];

// TODO: update this with correct wowcher ie value
export const FOOTER_LEGAL_LINKS = [
  {
    href: 'https://www.5pm.co.uk/page/e-commerceterms#contract',
    text: 'Billing Terms & Conditions',
  },
  {
    href: 'https://help.wowcher.co.uk/knowledgebase/s/article/Refunds',
    text: 'Refunds & Returns',
  },
  { href: 'https://www.5pm.co.uk/page/termsofuse', text: 'Terms of use' },
  {
    href: 'https://www.5pm.co.uk/page/privacypolicy',
    text: 'Privacy & Cookies Policy',
  },
  {
    href: 'https://www.5pm.co.uk/page/e-commerceterms',
    text: 'E-Commerce Terms',
  },
  {
    href: 'https://www.5pm.co.uk/page/internetsafety',
    text: 'Internet Safety',
  },
  {
    href:
      'https://www.5pm.co.uk/page/uk-modern-slavery-act-transparency-statement',
    text: 'UK modern slavery act',
  },
  {
    href: 'https://www.5pm.co.uk/page/takedown',
    text: 'Takedown Policy',
  },
  isKlarnaEnabled && {
    href: 'https://www.5pm.co.uk/page/klarna-faqs',
    text: 'Klarna FAQs',
  },
];

// TODO: update this with correct 5pm value
export const FOOTER_DEALS_LINKS = [
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/deals/shop`,
    text: 'Shopping',
  },
  /* {
    href: `${LINKS_GLOBAL_5PM[ENV]}/guides/holiday-deals`,
    text: 'Holiday Deals',
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/guides/hub`,
    text: 'Guides',
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/giftcard/redeem`,
    text: 'Redeem Gift Card',
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/near-me/hub`,
    text: 'Near Me',
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/guides/black-friday-deals`,
    text: 'Black Friday Deals',
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/discountcodes`,
    text: 'Discount Codes',
  }, */
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/editorial-team`,
    text: 'Meet Our Writers',
  },
];
export const DISCOUNT_CODE_LINK = 'https://www.5pm.co.uk/discountcodes';

export const FOOTER_ABOUT_US = 'About Us';
export const FOOTER_MERCHANT_FAQ = 'Merchant FAQs';
export const FOOTER_AFFILIATE_FAQ = 'Affiliate FAQs';
export const FOOTER_PRESS = 'Press FAQs';

// TODO: update this with correct 5pm value
export const FOOTER_COMPANY_LINKS = [
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/page/aboutus`,
    text: FOOTER_ABOUT_US,
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/page/workwithus`,
    text: FOOTER_MERCHANT_FAQ,
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/guides/5pm-affiliates`,
    text: FOOTER_AFFILIATE_FAQ,
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/guides/press-resources`,
    text: FOOTER_PRESS,
  },
  // {
  //   href:
  //     'https://blog.wowcher.co.uk/?_ga=2.267451022.107186891.1597051109-1566888493.1580121875',
  //   text: 'Blog',
  // },
];

// TODO - need confirmation
export const WORK_WITH_US = {
  fivepm: 'https://www.5pm.co.uk/page/workwithus.html',
  livingsocial: '',
  livingsocialie: '',
  wowcher: 'https://www.wowcher.co.uk/page/workwithus.html',
  wowcherie: 'https://www.wowcher.ie/page/workwithus.html',
};

export const GIFT_CARD_TERMS = '/page/giftcardterms';
export const NEED_HELP = 'Need Help';
export const FOOTER_REFUNDS_RETURNS = 'Refunds and Returns';
export const FOOTER_CONTACT_US = 'Contact Us';
export const FOOTER_RECALLS = 'Product Recalls';
export const FOOTER_COMPLAINTS = 'Complaints';

// TODO: update this with correct 5pm value
export const FOOTER_CUSTOMER_SERVICE_LINKS = [
  {
    href: `https://help.wowcher.co.uk`,
    text: NEED_HELP,
  },
  {
    href: `https://help.wowcher.co.uk/knowledgebase/s/article/Refunds`,
    text: FOOTER_REFUNDS_RETURNS,
  },
  {
    href: `https://help.wowcher.co.uk/articles/faqs/i-would-like-to-make-a-complaint/649c271a42417c2dcb614222`,
    text: FOOTER_COMPLAINTS,
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/page/productrecalls`,
    text: FOOTER_RECALLS,
  },
];
// TODO: do we need any here, ITR-1995 has none
export const MORE_POPOVER_LINKS_FIVEPM = [];

export const MORE_POPOVER_LINKS = [
  { href: 'https://help.wowcher.co.uk', text: 'Need Help' },
  // {
  //   href: `${LINKS_GLOBAL_5PM[ENV]}/myaccount/refer-a-friend`,
  //   text: 'Refer A Friend',
  // },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/page/workwithus`,
    text: 'Work With Us',
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/page/student-discount`,
    text: 'Student Discount',
  },
];

export const LOGIN_MENU = [
  {
    href: `/myaccount/vouchers`,
    text: 'My Vouchers',
  },
  {
    href: `/myaccount/profile`,
    text: 'My Details',
  },
];

// RAF enabled menu (for wowcher/wowcherie only)
export const RAF_ENABLED_LOGIN_MENU = [
  ...LOGIN_MENU,
  {
    href: `/myaccount/refer-a-friend`,
    text: 'Refer a Friend',
  },
];
export const REGISTER_MENU = [
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/${LOGIN_URL}`,
    text: 'Login',
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/${REGISTER_URL}`,
    text: 'Sign Up',
  },
];

export const MOBILE_MENU_MORE_LINKS = [
  { href: 'https://help.wowcher.co.uk', text: 'Need Help' },
  // {
  //   href: `${LINKS_GLOBAL_5PM[ENV]}/page/workwithus`,
  //   text: 'Work With Us',
  // },
  // { href: 'https://marketplace.5pm.co.uk', text: 'Create a deal' },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/page/aboutus`,
    text: 'About Us',
  },
  {
    href: `https://help.wowcher.co.uk/knowledgebase/s/`,
    text: 'FAQs',
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/page/privacypolicy`,
    text: 'Privacy & Cookie Policy',
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/page/termsofuse`,
    text: 'Terms of use',
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/page/e-commerceterms`,
    text: 'E-commerce terms',
  },
  {
    href: `${LINKS_GLOBAL_5PM[ENV]}/page/internetsafety`,
    text: 'Internet Safety',
  },
  // {
  //   href: `${LINKS_GLOBAL_5PM[ENV]}/giftcard/redeem`,
  //   text: 'Redeem Gift Card',
  // },
];

// Gifting
export const GIFTING_LINK = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1/checkout/config/gifting`;

// We only get 2 deals for page > 0
export const ONLY_MAIN_DEAL_EXTRA_SUFFIX = '?pageSize=##NUMBER##';

// We show 9 deals per page so let's make the request based on this number
export const NUMBER_OF_DEALS = 9;
export const DESKTOP_NUMBER_OF_PAGES = 1;
export const DESKTOP_PAGE_SIZE = NUMBER_OF_DEALS * DESKTOP_NUMBER_OF_PAGES;
export const SEARCH_PAGE_SIZE = 12;
export const GUIDE_PAGE_SIZE = 30;
export const DEFAULT_TOTAL_GUIDE_DEALS = 100;

// Get Deals links

export const MAIN_DEALS_DEFAULT_LINK = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1/product-listing-page-deal/national-deal`;
export const MAIN_DEALS_PREFIX_LINK = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1`;
export const PAGE_SIZE_SUFFIX = `?pageSize=${DESKTOP_PAGE_SIZE}`;
export const DEALS_SUFFIX_LINK_PAGE_NUMBER = '&page=##NUMBER##';
export const DEALS_PAGE_PARAMETER = 'page';
export const SCROLLER_CONFIGURATION_LINK = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1/content/scroller-configuration?brand=##brand##&sub-brand=5pm`;
export const NEW_PRODUCTS_LINK = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1/product-listing-page-deal/##location##/special/new-products`;
export const LINKS_GLOBAL_ONETRUST =
  'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';

export const LINKS_GLOBAL_ONETRUST_AUTOBLOCK = `https://cdn-ukwest.onetrust.com/consent/${process.env.NEXT_PUBLIC_ONETRUST_KEY}/OtAutoBlock.js`;
export const LINKS_GLOBAL_DTM = {
  dev:
    '//assets.adobedtm.com/db068b679b47/6a3293276648/launch-c08ee5ef4467-development.min.js',
  nxt:
    '//assets.adobedtm.com/db068b679b47/6a3293276648/launch-45e6c253f8aa-staging.min.js',
  prod:
    '//assets.adobedtm.com/db068b679b47/6a3293276648/launch-3fe5e3b3ffce.min.js',
};
// TODO: update this with correct 5pm value
export const LINKS_GLOBAL_CWV = {
  dev: '',
  nxt: 'https://rum.corewebvitals.io/cwv/65ae7a2e4f27fb498cf8a247.js',
  prod: 'https://rum.corewebvitals.io/cwv/65ae7a2e4f27fb498cf8a247.js',
};
// TODO: update this with correct 5pm value
export const LINKS_GLOBAL_AWIN = {
  dev: 'https://www.dwin1.com/108580.js',
  nxt: 'https://www.dwin1.com/108580.js',
  prod: 'https://www.dwin1.com/108580.js',
};
// TODO: update this with correct 5pm value
export const LINKS_GLOBAL_PAYPAL = {
  dev:
    'ASOxT2nj3kc5OkR2f4O3Mg5Bt88XBGIYIdHqPg1sUORhl-cLpGyLvgW6OocfgOi7qNmUpJzdJEzTsfFr',
  nxt:
    'ASOxT2nj3kc5OkR2f4O3Mg5Bt88XBGIYIdHqPg1sUORhl-cLpGyLvgW6OocfgOi7qNmUpJzdJEzTsfFr',
  prod: 'AR951hC7nvA6w4AR3uK4aC9APN7PU0fBUQiBfDqt0h8CyEryokAB9yHjeaDT',
};
// TODO: update this with correct 5pm value
export const AWIN_ADVERTISER_ID = 108_580;

// CHECKOUT

export const LINK_ECOMMERCE_TERMS = {
  href: 'https://www.5pm.co.uk/page/e-commerceterms',
  text: 'E-Commerce Terms',
};
export const ECOMMERCE_TERMS = 'https://www.5pm.co.uk/page/e-commerceterms';

// export const HREF_LANG_URL_DOMAINS = {
//   dev: {
//     livingsocial: 'https://www09.devlivingsocial.co.uk',
//     livingsocialie: 'https://www09.devlivingsocialie.net',
//     wowcher: 'https://www09.devwowcher.co.uk',
//     wowcherie: 'https://www09.devwowcherie.net',
//   },
//   nxt: {
//     livingsocial: 'https://www02.nxtlivingsocial.co.uk',
//     livingsocialie: 'https://www02.nxtlivingsocialie.net',
//     wowcher: 'https://www02.nxtwowcher.co.uk',
//     wowcherie: 'https://www02.nxtwowcherie.net',
//   },
//   prod: {
//     livingsocial: 'https://www.livingsocial.co.uk',
//     livingsocialie: 'https://www.livingsocial.ie',
//     wowcher: 'https://www.wowcher.co.uk',
//     wowcherie: 'https://www.wowcherie.net',
//   },
// };

export const SYMBOL = '£';
export const LINK_FILTER_API = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API
}/v1/deal`;

export const LINK_NOTFOUNDHIT = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API_TEST
}/v1/notFoundHit`;

// end-point to add general logs to splunk
export const POST_LOGS = `${
  process.env.NEXT_PUBLIC_PUBLIC_API || DEFAULT_PUBLIC_API_TEST
}/v1/log`;

export const CLOUDFLARE_TRACE = 'https://www.cloudflare.com/cdn-cgi/trace';

export const PAYPAL_CREDIT_LINK =
  'https://www.paypal.com/uk/webapps/mpp/paypal-virtual-credit/faq';

export const emailPreferences5pmLink = (email) =>
  `https://preferences.wowcher.co.uk/index/v2?email=${email}`;

// WALLET
export const WALLET_TERMS_OF_USE = '/page/wallettermsofuse';
export const WALLET_CREDIT_INFO = '/page/wallet-credit-more-info';

// VIP T&C
export const LINK_VIP_TERMS =
  'https://www.5pm.co.uk/page/vip-terms-and-conditions';

// PMV T&C
export const LINK_PMV_TERMS =
  'https://www.5pm.co.uk/page/e-commerceterms#protect-my-voucher';
export const LINK_CONTACT_US = `https://api.whatsapp.com/send?phone=447917171746`;

// VVE
export const LINK_VVE_TERMS =
  'https://www.5pm.co.uk/page/e-commerceterms#voucher-validity-extensions';

// Admin Fee Terms
export const ADMIN_FEE_TERMS = 'https://www.5pm.co.uk/page/admin-fee';

export const REDEEM_IMAGE_TYPES = {
  calendar: 'calendar',
  callToBook: 'call-to-book',
  chooseYourFlight: 'choose-your-flight',
  mysteryTravel: 'mystery-travel',
  standard: 'standard',
  standardTravel: 'standard-travel',
};

export const REDEEM_IMAGES = {
  fivepm: {
    [REDEEM_IMAGE_TYPES.calendar]:
      'https://resources.wowcher.co.uk/assets/img/5pm_redeem_travel_calendar.svg',
    [REDEEM_IMAGE_TYPES.callToBook]:
      'https://resources.wowcher.co.uk/assets/img/5pm_redeem_travel_merchant.svg',
    [REDEEM_IMAGE_TYPES.chooseYourFlight]:
      'https://resources.wowcher.co.uk/assets/img/5pm_redeem_int_travel.svg',
    [REDEEM_IMAGE_TYPES.mysteryTravel]:
      'https://resources.wowcher.co.uk/assets/img/5pm_redeem_mystery.svg',
    [REDEEM_IMAGE_TYPES.standard]:
      'https://resources.wowcher.co.uk/assets/img/5pm_redeem.svg',
    [REDEEM_IMAGE_TYPES.standardTravel]:
      'https://resources.wowcher.co.uk/assets/img/5pm_redeem_travel_standard.svg',
  },

  wowcher: {
    [REDEEM_IMAGE_TYPES.calendar]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-calendar.svg',
    [REDEEM_IMAGE_TYPES.callToBook]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-call-to-book.svg',
    [REDEEM_IMAGE_TYPES.chooseYourFlight]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-cyf.svg',
    [REDEEM_IMAGE_TYPES.mysteryTravel]:
      'https://resources.wowcher.co.uk/assets/img/brand/wowcher/wowcher-redeem-mystery-holiday.png',
    [REDEEM_IMAGE_TYPES.standard]:
      'https://resources.wowcher.co.uk/assets/img/how-to-redeem-wowcher.svg',
    [REDEEM_IMAGE_TYPES.standardTravel]:
      'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-travel-standard.svg',
  },
};

// APP Links

// TODO: update this with correct 5pm value
export const BRANCH_LINKS_PROD = 'https://wowcher.app.link/buzi0hczjqb';

// TODO: update this with correct 5pm value
export const BRANCH_LINKS_DEV = 'https://wowcher.test-app.link/G9OeOIoYhqb';

// Buy again variables
export const BUY_AGAIN_ICONS = {
  fivepm: 'https://resources.wowcher.co.uk/assets/img/Buy_Again_White.svg',
  wowcher:
    'https://s3.eu-west-1.amazonaws.com/resources.wowcher.co.uk/assets/img/Buy-Again.svg',
};

export const LINK_BUY_AGAIN = `/buy-again`;
