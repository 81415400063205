import find from 'lodash/find';
import forEach from 'lodash/forEach';
import Cookies from 'react-cookies';
import { scroller } from 'react-scroll';
import {
  PAYMENT_BUTTON_TRACKING_TYPES,
  PAYMENT_DOD_TRACKING_TYPES,
  PAYMENT_TYPES,
  MONTH_DEFAULT_VALUE,
  YEAR_DEFAULT_VALUE,
} from '../config/constants/payment';
import COOKIES from '../config/cookies/cookies';
import { PAYMENT_ELEMENT_NAME } from '../config/setup/setup';
import { trackEvent, updateDod } from './analytics';
import { isSamsung, isIE } from './browser';
import { basketHasHighRiskDeal, basketHasGamblingDeal } from './checkout';

export const scrollToPayment = () => {
  scroller.scrollTo(PAYMENT_ELEMENT_NAME, {
    delay: 100,
    duration: 1_000,
    smooth: true,
  });
};

const isApplePay = () => {
  if (typeof window === 'undefined') return false;

  return window.ApplePaySession && window.ApplePaySession.canMakePayments();
};

export const isGooglePayEnabled = () =>
  JSON.parse(process.env.NEXT_PUBLIC_GOOGLEPAY_ENABLED === 'true');

export const isApplePayEnabled = () =>
  JSON.parse(process.env.NEXT_PUBLIC_APPLEPAY_ENABLED === 'true');
/**
 * Check Payment Methods
 */
const checkPaymentMethod = ({ finalOrderAmount, paymentInstruments, type }) => {
  if (
    !paymentInstruments ||
    paymentInstruments.length === 0 ||
    !finalOrderAmount
  )
    return null;

  return paymentInstruments.find((method) => method.type === type);
};

/**
 * Check apple pay available
 */
export const showApplePay = ({
  finalOrderAmount,
  isAuthenticated,
  paymentInstruments,
}) => {
  if (isApplePay() && !isAuthenticated) return true;

  return (
    isApplePay() &&
    checkPaymentMethod({
      finalOrderAmount,
      paymentInstruments,
      type: PAYMENT_TYPES.apple,
    })
  );
};

/**
 * Check google pay available
 */
export const showGooglePay = ({
  finalOrderAmount,
  isAuthenticated,
  paymentInstruments,
}) => {
  if (!isApplePay() && !isSamsung() && !isIE() && !isAuthenticated) return true;

  return (
    !isApplePay() &&
    !isSamsung() &&
    !isIE() &&
    checkPaymentMethod({
      finalOrderAmount,
      paymentInstruments,
      type: PAYMENT_TYPES.google,
    })
  );
};

export const showPaypal = ({
  finalOrderAmount,
  isAuthenticated,
  paymentInstruments,
}) => {
  if (!isAuthenticated) return true;
  if (finalOrderAmount === 0) {
    return null;
  }

  return checkPaymentMethod({
    finalOrderAmount,
    paymentInstruments,
    type: PAYMENT_TYPES.paypal,
  });
};

export const showClearpay = ({
  basketProducts,
  finalOrderAmount,
  isAuthenticated,
  paymentInstruments,
}) => {
  const isClearpayEnabled = JSON.parse(
    process.env.NEXT_PUBLIC_CLEARPAY_ENABLED === 'true',
  );

  const isValidClearpayBasket =
    finalOrderAmount >= 1 && finalOrderAmount <= 1_000;
  if (
    !isAuthenticated &&
    isClearpayEnabled &&
    !basketHasHighRiskDeal(basketProducts) &&
    !basketHasGamblingDeal(basketProducts, true) &&
    isValidClearpayBasket
  ) {
    return true;
  }

  if (
    !basketProducts ||
    !paymentInstruments ||
    paymentInstruments?.length === 0 ||
    finalOrderAmount === 0 ||
    basketHasHighRiskDeal(basketProducts) ||
    !isClearpayEnabled
  ) {
    return null;
  }

  return checkPaymentMethod({
    finalOrderAmount,
    paymentInstruments,
    type: PAYMENT_TYPES.clearpay,
  });
};

export const showKlarna = ({
  basketProducts,
  finalOrderAmount,
  isAuthenticated,
  paymentInstruments,
}) => {
  // const isKlarnaEnabled = JSON.parse(
  //   process.env.NEXT_PUBLIC_KLARNA_ENABLED === 'true',
  // );
  const isKlarnaEnabled = false;
  if (
    !isAuthenticated &&
    isKlarnaEnabled &&
    !basketHasHighRiskDeal(basketProducts) &&
    !basketHasGamblingDeal(basketProducts)
  ) {
    return true;
  }

  if (
    !basketProducts ||
    !paymentInstruments ||
    paymentInstruments?.length === 0 ||
    finalOrderAmount === 0 ||
    basketHasHighRiskDeal(basketProducts) ||
    !isKlarnaEnabled
  ) {
    return null;
  }

  return checkPaymentMethod({
    finalOrderAmount,
    paymentInstruments,
    type: PAYMENT_TYPES.klarna,
  });
};

export const choosePaymentTypeTracking = (paymentType) => {
  if (paymentType === PAYMENT_TYPES.card) {
    trackEvent(PAYMENT_BUTTON_TRACKING_TYPES.card);
    updateDod({ paymentMethod: PAYMENT_DOD_TRACKING_TYPES.card });
  }
  if (paymentType === PAYMENT_TYPES.paypal) {
    trackEvent(PAYMENT_BUTTON_TRACKING_TYPES.paypal);
    updateDod({ paymentMethod: PAYMENT_DOD_TRACKING_TYPES.paypal });
  }
  if (paymentType === PAYMENT_TYPES.google) {
    trackEvent(PAYMENT_BUTTON_TRACKING_TYPES.google);
    updateDod({ paymentMethod: PAYMENT_DOD_TRACKING_TYPES.google });
  }
  if (paymentType === PAYMENT_TYPES.apple) {
    trackEvent(PAYMENT_BUTTON_TRACKING_TYPES.apple);
    updateDod({ paymentMethod: PAYMENT_DOD_TRACKING_TYPES.apple });
  }
  if (paymentType === PAYMENT_TYPES.klarna) {
    trackEvent(PAYMENT_BUTTON_TRACKING_TYPES.klarna);
    updateDod({ paymentMethod: PAYMENT_DOD_TRACKING_TYPES.klarna });
  }
  if (paymentType === PAYMENT_TYPES.clearpay) {
    trackEvent(PAYMENT_BUTTON_TRACKING_TYPES.clearpay);
    updateDod({ paymentMethod: PAYMENT_DOD_TRACKING_TYPES.clearpay });
  }
};

/**
 * Get month options
 */
export const getExpiryMonthOptions = () => {
  const monthsInYear = [{ name: 'Exp. Month', value: MONTH_DEFAULT_VALUE }];
  for (let index = 1, monLength = 12; index <= monLength; index++) {
    const dayFormatted = index <= 9 ? '0' + index : index; // display months with a leading zero
    monthsInYear.push({ name: dayFormatted, value: dayFormatted.toString(10) });
  }

  return monthsInYear;
};

/**
 * Get month options
 */
export const getExpiryYearOptions = () => {
  const currentDate = new Date();
  const currentYear = Number.parseInt(currentDate.getFullYear(), 10);

  const nextTenYears = [{ name: 'Exp. Year', value: YEAR_DEFAULT_VALUE }];
  for (let index = 0, yearLength = 10; index < yearLength; index++) {
    nextTenYears.push({
      name: currentYear + index,
      value: currentYear + index,
    });
  }

  return nextTenYears;
};

/**
 * Check expiry date
 */
export const isExpiryDateInThePast = (expiryMonth, expiryYear) => {
  if (!expiryMonth || !expiryYear) return false;

  const currentDate = new Date();
  const cardExpiryDate = new Date();
  cardExpiryDate.setFullYear(expiryYear, expiryMonth, 1);

  return cardExpiryDate < currentDate;
};

/**
 * Check card expiry date
 */
export const isCardExpiryDateInThePast = (expiryDate) => {
  if (!expiryDate) return null;

  const expiryArray = expiryDate.split('/');
  const expiryMonth = Number.parseInt(expiryArray[0], 10);
  const expiryYear = Number.parseInt(expiryArray[1], 10);

  const currentDate = new Date();
  const cardExpiryDate = new Date();
  cardExpiryDate.setFullYear(expiryYear, expiryMonth, 1);

  return cardExpiryDate < currentDate;
};

/**
 * split cc number with dashes
 */
export const splitCC = function (string) {
  if (!string) return null;

  return string.match(/.{1,4}/g).join(' - ');
};

/**
 * make walletCreditsVO for payment services
 */
export const getWalletCreditsVOForCurrency = (creditBalances, currency) => {
  if (!creditBalances) return null;
  let walletCreditsVO = { marketingWalletCredit: 0, refundableWalletCredit: 0 };
  forEach(creditBalances, (wallet) => {
    if (currency?.toLowerCase() === wallet?.currency?.toLowerCase()) {
      walletCreditsVO = {
        marketingWalletCredit: wallet.marketingBalance,
        refundableWalletCredit: wallet.refundableBalance,
      };
    }
  });

  return walletCreditsVO;
};

export const numbersOnlyRegExp = new RegExp('^\\d+$');

export const getGateway = (paymentInstruments) => {
  if (!paymentInstruments) return null;
  const isCreditCard = find(paymentInstruments, { type: 'credit_card' });

  return isCreditCard?.gateway || null;
};

export const getDefaultGateway = (paymentInstruments) => {
  if (!paymentInstruments) return null;
  const paymentType = find(paymentInstruments, {
    default: 'yes',
  });
  if (paymentType.type === 'credit_card' || paymentType.type === 'paypal')
    return paymentType.gateway;

  return null;
};

export const checkDefaultMethod = ({
  basketProducts,
  finalOrderAmount,
  isAuthenticated,
  paymentInstruments,
  type = null,
}) => {
  if (
    type === PAYMENT_TYPES.paypal &&
    showPaypal({
      finalOrderAmount,
      isAuthenticated,
      paymentInstruments,
    })
  ) {
    return type;
  }
  if (
    type === PAYMENT_TYPES.apple &&
    isApplePayEnabled() &&
    showApplePay({
      finalOrderAmount,
      isAuthenticated,
      paymentInstruments,
    })
  ) {
    return type;
  }
  if (
    type === PAYMENT_TYPES.google &&
    isGooglePayEnabled() &&
    showGooglePay({
      finalOrderAmount,
      isAuthenticated,
      paymentInstruments,
    })
  ) {
    return type;
  }
  if (
    type === PAYMENT_TYPES.clearpay &&
    showClearpay({
      basketProducts,
      finalOrderAmount,
      isAuthenticated,
      paymentInstruments,
    })
  ) {
    return type;
  }
  // if (
  //   type === PAYMENT_TYPES.klarna &&
  //   showKlarna({
  //     basketProducts,
  //     finalOrderAmount,
  //     isAuthenticated,
  //     paymentInstruments,
  //   })
  // ) {
  //   return type;
  // }

  return null;
};
